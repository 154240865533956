import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import cn from 'classnames'
import { atcb_action } from 'add-to-calendar-button'

import { useAppDispatch, useAppSelector } from 'store'
import $business from 'store/business'
import $order, { OrderType } from 'store/order'
import $service from 'store/service'
import $resource from 'store/resource'
import $day from 'store/day'

import App from 'components/App'

import styles from './HomeReservationsItem.module.scss'

export type Props = {
  item: OrderType
}

const HomeReservationsItem: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const about = useAppSelector($business.get.about)
  const settings = useAppSelector($business.get.settings)

  const [expand, setExpand] = useState(false)

  const handleExpand = () => {
    setExpand(!expand)
  }

  const handleCancel = () => {
    dispatch($order.set.cancelId(item.id))
  }

  const handleEdit = () => {
    dispatch($order.set.step(1))
    dispatch($order.set.comment(item.comment))
    dispatch($order.set.editId(item.id))

    const selectedServiceIds = item.services.map((service: { id: number, name: string }) => service.id)

    dispatch($service.set.selectedIds(settings.allow_multiple_services ? selectedServiceIds : [selectedServiceIds[0]]))
    dispatch($resource.set.selectedId(item.resource_id))
    dispatch($day.set.month({
      month: item.month,
      year: item.year,
      day: item.day,
      time: item.time,
    }))
  }

  const handleAddToCalendar = () => {
    atcb_action({
      name: item.services.map((service: {id: number, name: string}) => service.name).join(', '),
      startDate: moment(item.date).format('YYYY-MM-DD'),
      endDate: moment(item.date).format('YYYY-MM-DD'),
      startTime: item.time,
      endTime: item.end_time,
      timeZone: moment.tz.guess(),
      location: about.address,
      description: '',
      options: [
        'Apple',
        'Google',
        'Microsoft365',
        'Outlook.com',
        'Yahoo',
      ],
      iCalFileName: "Reminder-Event"
    })
  }
  
  return (
    <App.Card active>
      <App.Flex column fullWidth gap={4}>
        <App.Flex row aCenter jBetween className={styles.title}>
          <App.Flex row aCenter gap={1}>
            <App.Text onClick={handleExpand}>{item.services.map((service: {id: number, name: string}) => service.name).join(', ')}</App.Text>

            <App.Flex row center className={styles.add} onClick={handleAddToCalendar}>
              <App.Icon icon="calendar" />
            </App.Flex>
          </App.Flex>
          
          <App.Flex onClick={handleExpand}>
            <App.Icon icon="chevron-down" style={{ transform: `rotate(${expand ? 180 : 0}deg)` }} />
          </App.Flex>
        </App.Flex>

        <App.Flex fullWidth column>
          <App.Flex column gap={4} className={cn(styles.details, {[styles.hide]: expand})}>
            <App.Flex column gap={2}>
              <App.Text xs gray>{t('Details')}:</App.Text>

              <App.Flex row wrap gap={2}>
                <App.Tag icon="clock">{item.day} {t(moment(item.date).format('MMMM'))}, {item.time}</App.Tag>
                {item.resource !== '' ? <App.Tag icon="user">{item.resource}</App.Tag> : null}
                {settings.display_price ? <App.Tag>₪{item.price}</App.Tag> : null}
              </App.Flex>
            </App.Flex>

            {(item.allow_update || item.allow_cancel) && item.prepaid === 0 ? (
              <App.Flex className={cn(styles.line, styles.border)}>
              </App.Flex>
            ) : null}
          </App.Flex>

          <App.Flex column gap={4} className={cn(styles.expand, {[styles.show]: expand})}>
            <App.Flex column fullWidth gap={3}>
              <App.Flex row aCenter jBetween gap={2}>
                <App.Text xs gray>{t('Service')}:</App.Text>
                <App.Tag>{item.services.map((service: {id: number, name: string}) => service.name).join(', ')}</App.Tag>
              </App.Flex>

              {item.resource !== '' ? (
                <App.Flex row aCenter jBetween gap={2}>
                  <App.Text xs gray>{t('Specialist')}:</App.Text>
                  <App.Tag icon="user">{item.resource}</App.Tag>
                </App.Flex>
              ) : null}

              <App.Flex row aCenter jBetween gap={2}>
                <App.Text xs gray>{t('Date')}:</App.Text>
                <App.Tag icon="clock">{item.day} {t(moment(item.date).format('MMMM'))}, {item.time}</App.Tag>
              </App.Flex>

              {item.comment ? (
                <App.Flex row aCenter jBetween gap={2}>
                  <App.Text xs gray>{t('Comment')}:</App.Text>
                  <App.Text xs>{item.comment}</App.Text>
                </App.Flex>
              ) : null}
            </App.Flex>

            <App.Flex column className={cn(styles.line, {[styles.border]: item.allow_update || item.allow_cancel || settings.display_price})}>
              {settings.display_price ? (
                <App.Flex column fullWidth>
                  <App.Flex row aCenter jBetween gap={2} paddingTop={4}>
                    <App.Text small bold>{t('Total')}:</App.Text>
                    <App.Tag>₪{item.price}</App.Tag>
                  </App.Flex>
                </App.Flex>
              ) : null}

              {settings.display_price && item.prepaid > 0 ? (
                <App.Flex column fullWidth>
                  <App.Flex row aCenter jBetween gap={2} paddingTop={4}>
                    <App.Text small bold>{t('Prepaid')}:</App.Text>
                    <App.Tag>₪{item.prepaid}</App.Tag>
                  </App.Flex>
                </App.Flex>
              ) : null}
            </App.Flex>
          </App.Flex>
        </App.Flex>

        {(item.allow_update || item.allow_cancel) && item.prepaid === 0 ? (
          <App.Flex row aCenter gap={2}>
            {item.allow_update ? (
              <App.Button small outlined flex={1} icon="edit" onClick={handleEdit}>{t('Edit')}</App.Button>
            ) : null}

            {item.allow_cancel ? (
              <App.Button small outlined flex={1} variant="danger" icon="trash" onClick={handleCancel}>{t('Cancel')}</App.Button>
            ) : null}
          </App.Flex>
        ) : null}
      </App.Flex>
    </App.Card>
  )
}

export default HomeReservationsItem