import moment from 'moment'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { request, businessCode } from './index'

export type OrderType = {
  id: number
  services: Array<{id: number, name: string}>
  resource: string
  resource_id: number
  price: number
  start_time: number
  date: string
  day: number
  time: string
  end_time: string
  month: number
  year: number
  comment: string
  prepaid: number
  status: number
  allow_cancel: boolean
  allow_update: boolean
}

type StateType = {
  all: Array<OrderType>
  step: number
  comment: string
  fetch: boolean
  cancelId: number | null
  editId: number | null
  payment: {
    iframe: string
    orderId: number | null
    amount: number
    isPrepay: boolean
  }
}

const initialState: StateType = {
  all: [],
  step: 1,
  comment: '',
  fetch: true,
  cancelId: null,
  editId: null,
  payment: {
    iframe: '',
    orderId: null,
    amount: 0,
    isPrepay: false,
  }
}

export const orderSlice = createSlice({
  name: '$order',

  initialState,

  reducers: {
    all: (state: StateType, action: PayloadAction<Array<any>>) => {
      const groupedOrders = action.payload.reduce((acc, item) => {
        let price = Number(item.item_data.price)

        const options = item.item_data.resources_options?.[item.resource_id]
        if (options && options.hasOwnProperty('price') && options.price !== null && options.price !== '') {
          price = Number(options.price)
        }

        if (!acc[item.order_id]) {
          acc[item.order_id] = {
            id: item.order_id,
            services: [{
              id: item.item_id,
              name: item.name
            }],
            resource: item.resource_name,
            resource_id: item.resource_id,
            price,
            start_time: moment(item.start_time).valueOf(),
            date: moment(item.start_time).format('YYYY-MM-DD HH:mm'),
            day: Number(moment(item.start_time).format('D')),
            time: moment(item.start_time).format('HH:mm'),
            end_time: moment(item.end_time).format('HH:mm'),
            month: Number(moment(item.start_time).format('M')),
            year: Number(moment(item.start_time).format('YYYY')),
            comment: item.notes,
            prepaid: Number(item.prepaid),
            status: item.order_status,
            allow_cancel: item.allow_cancel,
            allow_update: item.allow_update,
          }
        } else {
          acc[item.order_id].services.push({
            id: item.item_id,
            name: item.name
          })

          acc[item.order_id].price += price
          acc[item.order_id].prepaid += Number(item.prepaid)
        }

        return acc
      }, {} as { [key: number]: OrderType[] })
      
      const sortedOrders = (Object.values(groupedOrders) as OrderType[]).sort((a: OrderType, b: OrderType) => (a.start_time - b.start_time))
      state.all = sortedOrders
    },

    step: (state: StateType, action: PayloadAction<number>) => {
      state.step = action.payload
    },

    comment: (state: StateType, action: PayloadAction<string>) => {
      state.comment = action.payload
    },

    fetch: (state: StateType, action: PayloadAction<boolean>) => {
      state.fetch = action.payload
    },

    cancelId: (state: StateType, action: PayloadAction<number | null>) => {
      state.cancelId = action.payload
    },

    editId: (state: StateType, action: PayloadAction<number | null>) => {
      state.editId = action.payload
    },

    payment: (state: StateType, action: PayloadAction<{iframe: string, orderId: number | null, amount: number, isPrepay: boolean}>) => {
      state.payment = action.payload
    },

    reset: (state: StateType) => {
      state.step = 1
      state.comment = ''
      state.cancelId = null
      state.editId = null
      state.payment = {
        iframe: '',
        orderId: null,
        amount: 0,
        isPrepay: false,
      }
    },
  },
})

const api = {
  book: (params: object) => {
    return request(`book/id/${businessCode()}`, 'POST', params)
  },

  all: (params: object) => {
    return request(`orders/id/${businessCode()}`, 'POST', params)
  },

  cancel: (params: object) => {
    return request(`remove/id/${businessCode()}`, 'POST', params)
  },

  update: (params: object) => {
    return request(`edit/id/${businessCode()}`, 'POST', params)
  },

  paid: (params: object) => {
    return request(`order_paid/id/${businessCode()}`, 'POST', params)
  },
}

const order = {
  reducer: orderSlice.reducer,
  set: orderSlice.actions,
  api,
}

export default order