import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import * as Sentry from '@sentry/react'

import store from 'store'
import i18n from 'utils/i18n'

import App from 'App'
import TheFallback from 'components/TheFallback'

import 'scss/app.scss'

// Sentry.init({
//   dsn: 'https://948fc941a2bc0db6c34983be51daa02b@o4508227485958144.ingest.de.sentry.io/4508227489038416',
//   environment: process.env.REACT_APP_ENV || 'development',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.captureConsoleIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   tracesSampleRate: 1.0,
//   tracePropagationTargets: ['localhost', /^https:\/\/stagebooking\.tiffulit\.co\.il/, /^https:\/\/booking\.tiffulit\.co\.il/],
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  // <React.StrictMode>
  <Sentry.ErrorBoundary fallback={({ error, componentStack, eventId, resetError }) => <TheFallback error={error as Error} componentStack={componentStack} eventId={eventId} resetError={resetError} />}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </Sentry.ErrorBoundary>
  // </React.StrictMode>
)
