import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { request } from './index'

export type BusinessType = {
  id: number,
  company_id: string,
  type: number,
  allow_online_booking: number,
  name: string,
  address: string,
  city: string,
  phone: string | null,
  fax: string | null,
  slogan: string | null,
  language_code: string,
  online_boking_data: {[key: string]: any},
}

type StateType = {
  code: string
  data: BusinessType
}

const initialState: StateType = {
  code: '',
  data: {} as BusinessType,
}

export const businessSlice = createSlice({
  name: '$business',

  initialState,

  reducers: {
    code: (state: StateType, action: PayloadAction<string>) => {
      state.code = action.payload
    },

    data: (state: StateType, action: PayloadAction<BusinessType>) => {
      state.data = action.payload
    },
  },
})

const get = {
  design: createSelector([
    state => state.$business.data,
  ], (data) => {
    const slides = []
    if (data.online_booking_data?.background && data.online_booking_data.background !== '') {
      slides.push(data.online_booking_data.background)
    }

    if (data.online_booking_data?.background1 && data.online_booking_data.background1 !== '') {
      slides.push(data.online_booking_data.background1)
    }

    if (data.online_booking_data?.background2 && data.online_booking_data.background2 !== '') {
      slides.push(data.online_booking_data.background2)
    }

    if (data.online_booking_data?.background3 && data.online_booking_data.background3 !== '') {
      slides.push(data.online_booking_data.background3)
    }

    return {
      color: data.online_booking_data?.online_color || null,
      text: data.online_booking_data?.online_text_color || null,
      background: slides.length > 0 ? slides[0] : null,
      icon: data.online_booking_data?.icon || null,
      logo: data.online_booking_data?.online_logo || null,
      slides,
      loaded: data?.id,
    }
  }),

  about: createSelector([
    state => state.$business.data,
  ], (data) => {
    return {
      name: data.online_booking_data?.name || '',
      description: data.online_booking_data?.about || '',
      address: data.online_booking_data?.address || [data.city, data.address].join(', '),
      phone: data.online_booking_data?.phone || '',
      whatsapp: data.online_booking_data?.phone || '',
      facebook: data.online_booking_data?.facebook || '',
      instagram: data.online_booking_data?.instagram || '',
      linkedin: data.online_booking_data?.linkedin || '',
      twitter: data.online_booking_data?.twitter || '',
      loaded: data?.id,
    }
  }),

  settings: createSelector([
    state => state.$business.data,
  ], (data) => {
    return {
      display_category: data.online_booking_data?.display_category === '1',
      display_price: data.online_booking_data?.display_price === '1',
      display_duration: data.online_booking_data?.display_duration === '1',
      display_earliest: data.online_booking_data?.display_earliest === '1',
      display_resource: data.online_booking_data?.resource === '1',
      display_waiting_list: data.online_booking_data?.waiting_list === '1',
      disable_confirmation_code: data.online_booking_data?.disable_confirmation_code === '1',
      allow_multiple_services: data.online_booking_data?.allow_multi === '1',
      facebook_pixel: data.online_booking_data?.facebook_pixel,
      loaded: data?.id,
    }
  }),

  manifest: createSelector([
    state => state.$business.data,
  ], (data) => {
    return {
      name: data.online_booking_data?.name || '',
      short_name: data.online_booking_data?.name || '',
      description: data.online_booking_data?.address || '',
      start_url: window.location.href,
      background_color: '#fff',
      theme_color: data.online_booking_data?.online_color || '',
      display: 'fullscreen',
      orientation: 'portrait',
      icons: [{
        src: data.online_booking_data?.icon || '',
        type: 'image/png',
        sizes: '512x512',
      }],
      confirmation_code: data.online_booking_data?.confirmation_code || '',
    }
  }),
}

const api = {
  get: (code: string) => {
    return request(`info/id/${code}`)
  },
}

const business = {
  reducer: businessSlice.reducer,
  set: businessSlice.actions,
  get,
  api,
}

export default business