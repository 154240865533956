import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { request, businessCode } from './index'
import { ServiceType } from './service'

export type ResourceType = {
  id: number
  name: string
  avatar: string
  order: string
}

type StateType = {
  all: Array<ResourceType>
  selectedId: number | null
}

const initialState: StateType = {
  all: [],
  selectedId: null,
}

export const resourceSlice = createSlice({
  name: '$resource',

  initialState,

  reducers: {
    all: (state: StateType, action: PayloadAction<Array<ResourceType>>) => {
      state.all = action.payload.map((item: ResourceType) => {
        return {
          ...item,
          avatar: item.avatar === 'assets/images/avatar.jpg' ? '' : item.avatar,
        }
      })
    },

    selectedId: (state: StateType, action: PayloadAction<number | null>) => {
      state.selectedId = action.payload
    },

    reset: (state: StateType) => {
      state.selectedId = null
    },
  },
})

const get = {
  selected: createSelector([
    state => state.$resource.all,
    state => state.$resource.selectedId,
  ], (resources, id) => {
    return resources.find((item: ResourceType) => item.id === id) ?? null
  }),

  filtered: createSelector([
    state => state.$resource.all,
    state => state.$service.all,
    state => state.$service.selectedIds,
  ], (resources, services, selectedServiceIds) => {
    if (selectedServiceIds.length) {
      const selectedServices = services.filter((service: ServiceType) => selectedServiceIds.includes(service.id))
      if (selectedServices.length > 0) {
        let commonResources = selectedServices[0].resources.map(String)

        for (let i = 1; i < selectedServices.length; i++) {
          const serviceResources = selectedServices[i].resources.map(String)
          commonResources = commonResources.filter((resource: string) => serviceResources.includes(resource))
        }

        return resources.filter((resource: ResourceType) =>
          commonResources.includes(String(resource.id))
        )
      }
    }
  
    return resources
  }),

  isDisabled: createSelector([
    state => state.$service.selectedIds,
  ], (selectedServiceIds) => {
    return !selectedServiceIds.length
  }),
}

const api = {
  all: () => {
    return request(`resources/id/${businessCode()}`)
  },
}

const resource = {
  reducer: resourceSlice.reducer,
  set: resourceSlice.actions,
  get,
  api,
}

export default resource