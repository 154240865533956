import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { request, businessCode } from './index'

export type ServiceType = {
  id: number
  name: string
  name_second: string
  bg_color: string
  categories: number
  duration: number
  duration_second: number
  break: number
  real_duration: number
  formated_duration: {h: number, m: number}
  item_image: string | null
  item_type: string
  card_balance: number
  price: number
  stock: string
  norders: number
  resources: Array<number>,
  resources_options: any,
}

type StateType = {
  all: Array<ServiceType>
  selectedIds: Array<number>
}

const initialState: StateType = {
  all: [],
  selectedIds: [],
}

const processService = (item: ServiceType, selectedResourceId: string | null): ServiceType => {
  if (selectedResourceId !== null && item.resources_options !== null) {
    const newItem = { ...item }
    const options = item.resources_options[selectedResourceId]
    if (options && options.hasOwnProperty('price')) {
      newItem.price = Number(options.price !== null && options.price !== '' ? options.price : item.price)
      newItem.real_duration = Number(options.duration !== null && options.duration !== '' ? options.duration : item.real_duration)

      if (newItem.real_duration > 60) {
        newItem.formated_duration = {
          h: Math.floor(newItem.real_duration / 60),
          m: newItem.real_duration % 60,
        }
      } else {
        newItem.formated_duration = {h: 0, m: newItem.real_duration}
      }
    }
    
    return newItem
  }
  return item
};

export const serviceSlice = createSlice({
  name: '$service',

  initialState,

  reducers: {
    all: (state: StateType, action: PayloadAction<Array<ServiceType>>) => {
      state.all = action.payload.map((item: ServiceType) => {
        const duration = item.duration ?? 0
        const duration_second = item.duration_second ?? 0
        const break_number = item.break ?? 0
        const real_duration = break_number > 0 ? (duration + duration_second + break_number) : duration
        const formated_duration = {h: 0, m: real_duration}
        const item_image = item.item_image === '' || !item.item_image ? null : item.item_image
        const card_balance = Number(item.card_balance)
        
        if (real_duration > 60) {
          formated_duration.h = Math.floor(real_duration / 60)
          formated_duration.m = real_duration % 60
        }

        return {
          ...item,
          categories: Number(item.categories),
          duration,
          duration_second,
          break_number,
          real_duration,
          formated_duration,
          item_image,
          card_balance,
          norders: item?.norders ?? 0,
        }
      })
    },

    selectedIds: (state: StateType, action: PayloadAction<Array<number>>) => {
      state.selectedIds = action.payload
    },

    reset: (state: StateType) => {
      state.selectedIds = []
    },
  },
})

const get = {
  selected: createSelector([
    state => state.$service.all,
    state => state.$service.selectedIds,
    state => state.$resource.selectedId,
  ], (services, ids, selectedResourceId) => {
    return services
      .filter((item: ServiceType) => ids.includes(item.id))
      .map((item: ServiceType) => processService(item, selectedResourceId)) ?? []
  }),

  filtered: createSelector([
    state => state.$service.all,
    state => state.$category.selectedIds,
    state => state.$resource.selectedId,
  ], (services, selectedCategoryIds, selectedResourceId) => {
    return services
      .filter((item: ServiceType) => selectedCategoryIds.length ? selectedCategoryIds.includes(item.categories) : true)
      .map((item: ServiceType) => processService(item, selectedResourceId))
  }),
}

const api = {
  all: (params: object) => {
    return request(`services/id/${businessCode()}`, 'POST', params)
  },
}

const service = {
  reducer: serviceSlice.reducer,
  set: serviceSlice.actions,
  get,
  api,
}

export default service